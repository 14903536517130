import React from 'react';

const SVG = () => {
  return (
    <svg width="120" height="10" xmlns="http://www.w3.org/2000/svg">
      <line x1="10" x2="100" y1="5" y2="5" style={{ stroke: '#cec0ff', strokeWidth: '1' }} />

      <circle cx="10" cy="5" r="4" stroke="black" strokeWidth="0" fill="rgba(206,192,255,0.5)"></circle>
    </svg> );
};

export default SVG;
